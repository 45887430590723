import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _02ed9a42 = () => interopDefault(import('../pages/absen/index.vue' /* webpackChunkName: "pages/absen/index" */))
const _181ef67a = () => interopDefault(import('../pages/bahan/index.vue' /* webpackChunkName: "pages/bahan/index" */))
const _d9439f64 = () => interopDefault(import('../pages/bank/index.vue' /* webpackChunkName: "pages/bank/index" */))
const _fb5e25d0 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _2c612736 = () => interopDefault(import('../pages/draft/index.vue' /* webpackChunkName: "pages/draft/index" */))
const _64ad6f31 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _6ad0f626 = () => interopDefault(import('../pages/index_catalog.vue' /* webpackChunkName: "pages/index_catalog" */))
const _f3f5c272 = () => interopDefault(import('../pages/jadwal/index.vue' /* webpackChunkName: "pages/jadwal/index" */))
const _1196f04a = () => interopDefault(import('../pages/kasir/index.vue' /* webpackChunkName: "pages/kasir/index" */))
const _281ef6a5 = () => interopDefault(import('../pages/katalog/index.vue' /* webpackChunkName: "pages/katalog/index" */))
const _8377c7f8 = () => interopDefault(import('../pages/konfigurasi/index.vue' /* webpackChunkName: "pages/konfigurasi/index" */))
const _5bd1e9f9 = () => interopDefault(import('../pages/kunjungan/index.vue' /* webpackChunkName: "pages/kunjungan/index" */))
const _adcf9d22 = () => interopDefault(import('../pages/laporan/index.vue' /* webpackChunkName: "pages/laporan/index" */))
const _84bc65fc = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _04b7ac06 = () => interopDefault(import('../pages/multi/index.vue' /* webpackChunkName: "pages/multi/index" */))
const _c32d067e = () => interopDefault(import('../pages/note.vue' /* webpackChunkName: "pages/note" */))
const _4f0a3e9f = () => interopDefault(import('../pages/panduan/index.vue' /* webpackChunkName: "pages/panduan/index" */))
const _1575144a = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _a171fe98 = () => interopDefault(import('../pages/pegawai/index.vue' /* webpackChunkName: "pages/pegawai/index" */))
const _34fa8656 = () => interopDefault(import('../pages/pelanggan/index.vue' /* webpackChunkName: "pages/pelanggan/index" */))
const _084adaf2 = () => interopDefault(import('../pages/pembayaran/index.vue' /* webpackChunkName: "pages/pembayaran/index" */))
const _1e3e02a2 = () => interopDefault(import('../pages/pengajuan/index.vue' /* webpackChunkName: "pages/pengajuan/index" */))
const _2ac91c3f = () => interopDefault(import('../pages/penjadwalan/index.vue' /* webpackChunkName: "pages/penjadwalan/index" */))
const _2d9a3b1a = () => interopDefault(import('../pages/pinjam/index.vue' /* webpackChunkName: "pages/pinjam/index" */))
const _66667182 = () => interopDefault(import('../pages/produk/index.vue' /* webpackChunkName: "pages/produk/index" */))
const _3b3872e4 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _911ad8e2 = () => interopDefault(import('../pages/riwayat/index.vue' /* webpackChunkName: "pages/riwayat/index" */))
const _724a580c = () => interopDefault(import('../pages/rutin/index.vue' /* webpackChunkName: "pages/rutin/index" */))
const _75b6c1ad = () => interopDefault(import('../pages/saldo/index.vue' /* webpackChunkName: "pages/saldo/index" */))
const _61ce0bf4 = () => interopDefault(import('../pages/shift/index.vue' /* webpackChunkName: "pages/shift/index" */))
const _7a2af2b7 = () => interopDefault(import('../pages/titipan/index.vue' /* webpackChunkName: "pages/titipan/index" */))
const _86c2d91e = () => interopDefault(import('../pages/toko/index.vue' /* webpackChunkName: "pages/toko/index" */))
const _324ddfa8 = () => interopDefault(import('../pages/transaksi/index.vue' /* webpackChunkName: "pages/transaksi/index" */))
const _0b1c61f0 = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _67c087eb = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _e63f585e = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7b17736b = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _46405394 = () => interopDefault(import('../pages/jadwal/desktop/index.vue' /* webpackChunkName: "pages/jadwal/desktop/index" */))
const _0bf38e98 = () => interopDefault(import('../pages/jadwal/new/index.vue' /* webpackChunkName: "pages/jadwal/new/index" */))
const _5f362147 = () => interopDefault(import('../pages/jadwal/tambah/index.vue' /* webpackChunkName: "pages/jadwal/tambah/index" */))
const _4b2370ca = () => interopDefault(import('../pages/kunjungan/new/index.vue' /* webpackChunkName: "pages/kunjungan/new/index" */))
const _e9e7dfe0 = () => interopDefault(import('../pages/penjadwalan/new/index.vue' /* webpackChunkName: "pages/penjadwalan/new/index" */))
const _93a33b36 = () => interopDefault(import('../pages/jadwal/new/desktop/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/index" */))
const _62d27456 = () => interopDefault(import('../pages/jadwal/new/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/tambah/index" */))
const _6aa52fe9 = () => interopDefault(import('../pages/jadwal/new/desktop/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/tambah/index" */))
const _d1c5b3da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4afe65dc = () => interopDefault(import('../pages/jadwal/new/desktop/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/desktop/ubah/_id" */))
const _7730e8c9 = () => interopDefault(import('../pages/jadwal/new/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/ubah/_id" */))
const _4e21e216 = () => interopDefault(import('../pages/transaksi/payment/multi/_id.vue' /* webpackChunkName: "pages/transaksi/payment/multi/_id" */))
const _5d3306ec = () => interopDefault(import('../pages/transaksi/payment/pembayaran/_id.vue' /* webpackChunkName: "pages/transaksi/payment/pembayaran/_id" */))
const _2156deb2 = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _49f79d72 = () => interopDefault(import('../pages/invoice/print/_slug.vue' /* webpackChunkName: "pages/invoice/print/_slug" */))
const _8dea938c = () => interopDefault(import('../pages/jadwal/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/ubah/_id" */))
const _51e056ea = () => interopDefault(import('../pages/transaksi/payment/_id.vue' /* webpackChunkName: "pages/transaksi/payment/_id" */))
const _f7634ce2 = () => interopDefault(import('../pages/feedback/_slug.vue' /* webpackChunkName: "pages/feedback/_slug" */))
const _94a3012e = () => interopDefault(import('../pages/invoice/_slug.vue' /* webpackChunkName: "pages/invoice/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/absen",
    component: _02ed9a42,
    name: "absen"
  }, {
    path: "/bahan",
    component: _181ef67a,
    name: "bahan"
  }, {
    path: "/bank",
    component: _d9439f64,
    name: "bank"
  }, {
    path: "/dashboard",
    component: _fb5e25d0,
    name: "dashboard"
  }, {
    path: "/draft",
    component: _2c612736,
    name: "draft"
  }, {
    path: "/home",
    component: _64ad6f31,
    name: "home"
  }, {
    path: "/index_catalog",
    component: _6ad0f626,
    name: "index_catalog"
  }, {
    path: "/jadwal",
    component: _f3f5c272,
    name: "jadwal"
  }, {
    path: "/kasir",
    component: _1196f04a,
    name: "kasir"
  }, {
    path: "/katalog",
    component: _281ef6a5,
    name: "katalog"
  }, {
    path: "/konfigurasi",
    component: _8377c7f8,
    name: "konfigurasi"
  }, {
    path: "/kunjungan",
    component: _5bd1e9f9,
    name: "kunjungan"
  }, {
    path: "/laporan",
    component: _adcf9d22,
    name: "laporan"
  }, {
    path: "/layanan",
    component: _84bc65fc,
    name: "layanan"
  }, {
    path: "/multi",
    component: _04b7ac06,
    name: "multi"
  }, {
    path: "/note",
    component: _c32d067e,
    name: "note"
  }, {
    path: "/panduan",
    component: _4f0a3e9f,
    name: "panduan"
  }, {
    path: "/payment",
    component: _1575144a,
    name: "payment"
  }, {
    path: "/pegawai",
    component: _a171fe98,
    name: "pegawai"
  }, {
    path: "/pelanggan",
    component: _34fa8656,
    name: "pelanggan"
  }, {
    path: "/pembayaran",
    component: _084adaf2,
    name: "pembayaran"
  }, {
    path: "/pengajuan",
    component: _1e3e02a2,
    name: "pengajuan"
  }, {
    path: "/penjadwalan",
    component: _2ac91c3f,
    name: "penjadwalan"
  }, {
    path: "/pinjam",
    component: _2d9a3b1a,
    name: "pinjam"
  }, {
    path: "/produk",
    component: _66667182,
    name: "produk"
  }, {
    path: "/profil",
    component: _3b3872e4,
    name: "profil"
  }, {
    path: "/riwayat",
    component: _911ad8e2,
    name: "riwayat"
  }, {
    path: "/rutin",
    component: _724a580c,
    name: "rutin"
  }, {
    path: "/saldo",
    component: _75b6c1ad,
    name: "saldo"
  }, {
    path: "/shift",
    component: _61ce0bf4,
    name: "shift"
  }, {
    path: "/titipan",
    component: _7a2af2b7,
    name: "titipan"
  }, {
    path: "/toko",
    component: _86c2d91e,
    name: "toko"
  }, {
    path: "/transaksi",
    component: _324ddfa8,
    name: "transaksi"
  }, {
    path: "/auth/check",
    component: _0b1c61f0,
    name: "auth-check"
  }, {
    path: "/auth/forgot",
    component: _67c087eb,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _e63f585e,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _7b17736b,
    name: "auth-success"
  }, {
    path: "/jadwal/desktop",
    component: _46405394,
    name: "jadwal-desktop"
  }, {
    path: "/jadwal/new",
    component: _0bf38e98,
    name: "jadwal-new"
  }, {
    path: "/jadwal/tambah",
    component: _5f362147,
    name: "jadwal-tambah"
  }, {
    path: "/kunjungan/new",
    component: _4b2370ca,
    name: "kunjungan-new"
  }, {
    path: "/penjadwalan/new",
    component: _e9e7dfe0,
    name: "penjadwalan-new"
  }, {
    path: "/jadwal/new/desktop",
    component: _93a33b36,
    name: "jadwal-new-desktop"
  }, {
    path: "/jadwal/new/tambah",
    component: _62d27456,
    name: "jadwal-new-tambah"
  }, {
    path: "/jadwal/new/desktop/tambah",
    component: _6aa52fe9,
    name: "jadwal-new-desktop-tambah"
  }, {
    path: "/",
    component: _d1c5b3da,
    name: "index"
  }, {
    path: "/jadwal/new/desktop/ubah/:id?",
    component: _4afe65dc,
    name: "jadwal-new-desktop-ubah-id"
  }, {
    path: "/jadwal/new/ubah/:id?",
    component: _7730e8c9,
    name: "jadwal-new-ubah-id"
  }, {
    path: "/transaksi/payment/multi/:id?",
    component: _4e21e216,
    name: "transaksi-payment-multi-id"
  }, {
    path: "/transaksi/payment/pembayaran/:id?",
    component: _5d3306ec,
    name: "transaksi-payment-pembayaran-id"
  }, {
    path: "/auth/reset/:slug?",
    component: _2156deb2,
    name: "auth-reset-slug"
  }, {
    path: "/invoice/print/:slug?",
    component: _49f79d72,
    name: "invoice-print-slug"
  }, {
    path: "/jadwal/ubah/:id?",
    component: _8dea938c,
    name: "jadwal-ubah-id"
  }, {
    path: "/transaksi/payment/:id?",
    component: _51e056ea,
    name: "transaksi-payment-id"
  }, {
    path: "/feedback/:slug?",
    component: _f7634ce2,
    name: "feedback-slug"
  }, {
    path: "/invoice/:slug?",
    component: _94a3012e,
    name: "invoice-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
